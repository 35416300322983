<template>
  <div class="project">
    <div class="titleBar">
      <PlateTitle title="授信额度" class="plate" />
    </div>
    <!-- 可用余额 -->
    <div
      class="credit-block"
      v-if="size === 'small' || size === 'large' || size === 'apply'"
    >
      <div class="left">
        <img src="../../assets/image/icon_ljqzje.png" alt="" />
        <div class="usemoney">
          <div class="title">可用余额(元)</div>
          <div class="money">{{ creditAmountAvailable }}</div>
        </div>
        <div
          v-if="lastStatus == null || lastStatus === '2'"
          class="incressLimit"
          @click="openLimit"
        >
          提额申请
        </div>
        <div
          v-else-if="lastStatus === '1'"
          class="incressLimit"
          style="color: #333"
        >
          提额审批中，请耐心等待
        </div>
        <div class="incressLimit" v-else-if="lastStatus === '3'">
          <span style="color: #333">提额失败</span>
          <span style="padding-left: 30px" @click="failvisible = true"
            >查看原因</span
          >
          <span style="padding-left: 30px" @click="showprepay = true"
            >重新申请</span
          >
        </div>
      </div>
      <div class="left">
        <img src="../../assets/image/icon_zed@2x.png" alt="" />
        <div class="usemoney">
          <div class="title">总额度(元)</div>
          <div class="money">{{ creditAmount }}</div>
        </div>
        <div class="incressLimit"></div>
      </div>
    </div>
    <div
      class="tab"
      v-if="size === 'small' || size === 'large' || size === 'apply'"
    >
      <a-radio-group v-model="size" style="margin-bottom: 16px">
        <a-radio-button value="small">使用记录 </a-radio-button>
        <a-radio-button value="large">额度记录 </a-radio-button>
        <a-radio-button value="apply">申请记录 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="limit">
      <img src="../../assets/image/icon_yyed@2x.png" alt="" />
      <div class="limit-block">
        {{
          size === 'small'
            ? '已用额度:' + creditAmountUsed
            : '总额度:' + creditAmount
        }}
        元
      </div>
    </div>
    <!-- 使用记录 -->
    <div class="usehistory" v-if="size === 'small'">
      <div class="search">
        <a-row>
          <a-col :span="10">
            <span>交易类型</span>
            <a-select v-model="query.type" @change="handleClickType">
              <a-select-option
                v-for="item in statusList"
                :key="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="10">
            <span>交易时间</span>
            <a-range-picker
              v-model="query.date"
              :locale="locale"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </a-col>
          <a-col :span="4" style="text-align: right">
            <a-button
              type="primary"
              style="background: #007ce2"
              @click="usetoQuery"
            >
              查询
            </a-button>
            <a-button @click="resetUse"> 重置 </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="useData"
          :pagination="usePagination"
          @change="handleTableChange"
        >
          <span class="tabProject" slot="name" slot-scope="space">
            {{ space }}
          </span>
          <span slot="createTime" slot-scope="space, text">
            {{ text.createTime ? dateFormat(text.createTime) : '' }}
          </span>
          <span slot="amount" slot-scope="space, text">
            <span v-if="text.type === '1'">+</span>
            <span v-else-if="text.type === '2'">-</span>
            {{ throundsHandle(text.amount) }}
          </span>
          <span slot="type" slot-scope="space, text">
            <span v-if="text.type === '1'">投保</span>
            <span v-else-if="text.type === '2'">退保</span>
            <span v-else-if="text.type === '3'">保函到期</span>
          </span>
          <span slot="action" slot-scope="space, text">
            <a @click="view(text)">查看</a>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 使用记录 -->
    <!-- 缴费记录 -->
    <div class="prepayhistory" v-if="size === 'large'">
      <div class="table">
        <div class="table">
          <a-table
            :columns="columnsYJ"
            :data-source="useData"
            :pagination="usePagination"
            @change="handleTableChange"
          >
            <span class="tabProject" slot="name" slot-scope="space">
              {{ space }}
            </span>
            <span slot="createTime" slot-scope="space, text">
              {{ text.createTime ? dateFormat(text.createTime) : '' }}
            </span>
            <span slot="source" slot-scope="space, text">
              <span v-if="text.source === '1'"> 初始额度 </span>
              <span v-else-if="text.source === '2'"> 额度提升 </span>
              <span></span>
            </span>
            <span slot="amount" slot-scope="space, text">
              {{ throundsHandle(text.amount) }}
            </span>
            <span slot="action" slot-scope="space, text">
              <a @click="view(text)">查看</a>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <!-- 缴费记录 -->
    <!-- 申请记录 -->
    <div class="prepayhistory" v-if="size === 'apply'">
      <div class="table">
        <div class="table">
          <a-table
            :columns="columnsApply"
            :data-source="useData"
            :pagination="usePagination"
            @change="handleTableChange"
          >
            <span class="tabProject" slot="name" slot-scope="space">
              {{ space }}
            </span>
            <span slot="createTime" slot-scope="space, text">
              {{ text.createTime ? dateFormat(text.createTime) : '' }}
            </span>
            <span slot="auditTime" slot-scope="space, text">
              {{ text.auditTime ? dateFormat(text.auditTime) : '' }}
            </span>
            <span slot="status" slot-scope="space, text">
              <span v-if="text.status === '1'">待审核</span>
              <span v-else-if="text.status === '2'">审核通过</span>
              <span v-else-if="text.status === '3'">审核不通过</span>
              <span v-else>{{ text.status }}</span>
            </span>
            <span slot="amount" slot-scope="space, text">
              {{ text.status === '1' ? '' : throundsHandle(text.amount) }}
            </span>
            <span slot="afterAmount" slot-scope="space, text">
              {{ text.status === '1' ? '' : throundsHandle(text.afterAmount) }}
            </span>
            <span slot="action" slot-scope="space, text">
              <a @click="view(text)">查看</a>
            </span>
            <span slot="action" slot-scope="space, text">
              <a-button @click="look(text)"> 查看 </a-button>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <!-- 提额申请 -->
    <a-modal
      title="提额申请"
      class="prepaymodal"
      :visible="showprepay"
      :footer="null"
      @cancel="showprepay = false"
    >
      <a-form
        :form="formPay"
        :label-col="{ span: 16 }"
        :wrapper-col="{ span: 8 }"
      >
        <a-form-item label="请上传最近一期财务报表（资产负债表及利润表）">
          <a-upload
            name="file"
            :multiple="true"
            :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
            :data="{ filePath: 'entCertForm' }"
            v-decorator="[
              'file',
              { rules: [{ required: true, message: '请选择' }] }
            ]"
            @change="handleChange"
          >
            <a-button class="upload-btn">
              <img
                style="width: 18px; height: 18px; margin-top: -4px"
                src="../../assets/image/icon_scwj.png"
                alt=""
              />
              <span style="color: #005bac; padding-left: 4px">上传文件</span>
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 10 }" class="entBtn">
          <a-button
            type="primary"
            :loading="payLoading"
            @click="handleClickPay"
          >
            提交
          </a-button>
          <a-button @click="showprepay = false"> 取消 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 查看 -->
    <a-modal
      title="查看"
      class="prepaymodal"
      :visible="lookvisible"
      :footer="null"
      width="800px"
      @cancel="lookvisible = false"
    >
      <a-row>
        <a-col :span="12">
          <div class="item">
            <span class="label">申请时间：</span>
            <span class="value">{{
              lookForm.createTime
                ? dateFormat(lookForm.createTime)
                : lookForm.createTime
            }}</span>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="item">
            <span class="label">审核时间：</span>
            <span class="value">{{
              lookForm.auditTime
                ? dateFormat(lookForm.auditTime)
                : lookForm.auditTime
            }}</span>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="item">
            <span class="label">审核状态：</span>
            <span class="value" v-if="lookForm.status === '1'">待审核</span>
            <span class="value" v-else-if="lookForm.status === '2'"
              >审核通过</span
            >
            <span class="value" v-else-if="lookForm.status === '3'"
              >审核失败</span
            >
            <span class="value" v-else>{{ lookForm.status }}</span>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="item">
            <span class="label">获得额度（元）：</span>
            <span class="value">
              {{
                lookForm.status === '1' ? '' : throundsHandle(lookForm.amount)
              }}
            </span>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="item">
            <span class="label">提额后可用额度（元）：</span>
            <span class="value">
              {{
                lookForm.status === '1'
                  ? ''
                  : throundsHandle(lookForm.afterAmount)
              }}
            </span>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="item upload-item">
            <span class="label">附件信息：</span>
            <div class="uploads">
              <a-upload
                class="value"
                @preview="handledDownload"
                @download="handledDownload"
                :default-file-list="defaultFileList"
                name="file"
                :showUploadList="{
                  showDownloadIcon: true,
                  showRemoveIcon: false,
                  showPreviewIcon: false
                }"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="item" style="display: flex; align-items: flex-start">
            <span class="label" style="padding-top: 0">原因：</span>
            <span class="value values">
              {{ lookForm.remark }}
            </span>
          </div>
        </a-col>
      </a-row>
      <a-form-item :wrapper-col="{ span: 12, offset: 10 }" class="entBtn">
        <a-button @click="lookvisible = false"> 关闭 </a-button>
      </a-form-item>
    </a-modal>
    <!-- 提示信息 -->
    <a-modal
      title="提示信息"
      class="prepaymodal"
      :visible="failvisible"
      :footer="null"
      @cancel="failvisible = false"
    >
      <div class="item">
        <span class="label">失败原因：</span>
        <span class="value">{{ lastRemark }}</span>
      </div>
      <div class="handle-blok" style="text-align: center;">
        <a-button
          type="primary"
          class="handle-btn"
          @click="failvisible = false"
        >
          关闭
        </a-button>
      </div>
    </a-modal>
    <!-- 操作成功 -->
    <a-modal
      title="提额"
      class="prepaymodal"
      :visible="showsuccess"
      :footer="null"
      @cancel="() => (showsuccess = false)"
    >
      <div class="handle-blok">
        <img src="../../assets/image/icon_cg.png" alt="" srcset="" />
        <div class="info">操作成功</div>
        <a-button type="primary" class="handle-btn" @click="handleClickClose">
          确定
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import { dateFormat, downloadFile } from '@/utils/format.js';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { getAmountInfo, applyRecordList, changeListCus, getList, applyIncrease } from '@/api/lineCredit.js';
export default {
  name: 'lineCredit',
  components: {
    PlateTitle,
  },
  data () {
    return {
      useData: [],
      usePagination: {
        total: 0
      },
      size: 'small',
      columns: [
        {
          title: '交易时间',
          dataIndex: 'createTime',
          key: 'createTime',
          slots: { title: '交易时间' },
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '项目名称',
          dataIndex: 'projectName',
          key: 'projectName',
        },
        {
          title: '交易金额(元)',
          dataIndex: 'amount',
          key: 'amount',
          slots: { title: '交易金额(元)' },
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '交易类型',
          dataIndex: 'type',
          key: 'type',
          slots: { title: '交易类型' },
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '预/保函单号',
          dataIndex: 'guaranteeNumber',
          key: 'guaranteeNumber',
        }
      ],
      columnsYJ: [
        {
          title: '额度获得时间',
          dataIndex: 'createTime',
          key: 'createTime',
          slots: { title: '额度获得时间' },
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '额度来源',
          dataIndex: 'source',
          key: 'source',
          slots: { title: '额度来源' },
          scopedSlots: { customRender: 'source' },
        },
        {
          title: '获得额度(元)',
          dataIndex: 'amount',
          key: 'amount',
          slots: { title: '获得额度(元)' },
          scopedSlots: { customRender: 'amount' },
        }
      ],
      columnsApply: [
        {
          title: '申请时间',
          dataIndex: 'createTime',
          key: 'createTime',
          slots: { title: '申请时间' },
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '审核时间',
          dataIndex: 'auditTime',
          key: 'auditTime',
          slots: { title: '审核时间' },
          scopedSlots: { customRender: 'auditTime' },
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          key: 'status',
          slots: { title: '审核状态' },
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '获取额度（元）',
          dataIndex: 'amount',
          key: 'amount',
          slots: { title: '获取额度（元）' },
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '提额后可用额度（元）',
          dataIndex: 'afterAmount',
          key: 'afterAmount',
          slots: { title: '提额后可用额度（元）' },
          scopedSlots: { customRender: 'afterAmount' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      VUE_APP_FIRST_URL: '',
      visible: false,
      typeList: [],
      statusList: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '投保',
          value: '1'
        },
        {
          label: '退保',
          value: '2'
        },
        {
          label: '保函到期',
          value: '3'
        },
      ],
      dateFormat,
      locale,
      title: {},
      query: {
        page: 1,
        size: 10,
        type: null,
        startTime: null,
        endTime: null,
      },
      orderList: [],
      loading: false,
      isPagination: false,
      creditAmountAvailable: null,
      creditAmount: null,
      creditAmountUsed: null,
      lastRemark: '',
      lastStatus: '',
      useData: [],
      usePagination: {
        total: 0
      },
      lookvisible: false,
      showprepay: false,
      formPay: this.$form.createForm(this, { name: 'payForm' }),
      lookInfo: this.$form.createForm(this, { name: 'lookInfos' }),
      lookForm: {},
      payLoading: false,
      defaultFileList: [],
      showsuccess: false,
      failvisible: false
    };
  },
  mounted () {
    this.getTableList()
    this.query.type = -1
    this.getInfo()
  },
  watch: {
    size () {
      this.useData = []
      this.usePagination.total = 0
      this.resetUse()
      this.getInfo()
    }
  },
  methods: {
    handledDownload (file) {
      if (file.name) {
        downloadFile(file.url, file.name.substring(0, file.name.lastIndexOf(".")), file.name.match(/.[^.]+$/)[0].split('.')[1]);
      } else {
        this.$message.warning('文件名缺失，无法下载！')
      }
    },
    handleClickClose () {
      this.showsuccess = false
      this.getInfo()
      this.query.page = 1
      if (this.size === 'small') {
        this.getTableList()
      } else if (this.size === 'large') {
        this.changeListCusList()
      } else if (this.size === 'apply') {
        this.getApplyInfo()
      }
    },
    handleClickPay () {
      this.formPay.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        this.payLoading = true
        const params = {
          files: JSON.stringify(this.fileImg)
        }
        applyIncrease(params).then(res => {
          this.payLoading = false
          this.showprepay = false
          this.showsuccess = true
        })
      })
    },
    openLimit () {
      this.showprepay = true
    },
    handleChange (info) {
      if (info.file.status === 'done') {
        if (info.fileList && info.fileList.length > 0) {
          this.fileImg = info.fileList.map(item => {
            return { fileName: item.name, filePath: item.response.data.url }
          })
        }
      }
    },
    look (text) {
      this.lookForm = text
      try {
        const files = JSON.parse(text.files)
        this.defaultFileList = files.map((item, index) => {
          return { name: item.fileName, url: item.filePath, uid: index, status: 'done' }
        })
      } catch {
      }
      this.lookvisible = true
    },
    formatDecimal (num) {
      if (num) {
        return this.throundsHandle(parseFloat(Number((num / 10000).toFixed(5))))
      } else {
        return num
      }
    },
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    handleClickType (e) {
      this.query.type = e
    },
    getInfo () {
      getAmountInfo().then(res => {
        this.creditAmount = res.data.creditAmount ? this.throundsHandle(res.data.creditAmount) : 0
        this.creditAmountAvailable = res.data.creditAmountAvailable ? this.throundsHandle(res.data.creditAmountAvailable) : 0
        this.creditAmountUsed = res.data.creditAmountUsed ? this.throundsHandle(res.data.creditAmountUsed) : 0
        this.lastRemark = res.data.lastRemark
        this.lastStatus = res.data.lastStatus
      })
    },
    // 页数改变
    handleTableChange (pagination) {
      this.query.page = pagination.current
      this.query.size = pagination.pageSize
      if (this.size === 'small') {
        this.query.startTime = this.query.date ? this.query.date[0] : null
        this.query.endTime = this.query.date ? this.query.date[1] : null
        this.getTableList()
      } else if (this.size === 'large') {
        this.query.type = -1
        this.changeListCusList()
      } else if (this.size === 'apply') {
        this.query.type = -1
        this.getApplyInfo()
      }
    },
    usetoQuery () {
      this.query.page = 1
      this.query.size = 10
      if (this.size === 'small') {
        this.query.startTime = this.query.date ? this.query.date[0] : null
        this.query.endTime = this.query.date ? this.query.date[1] : null
        this.getTableList()
      } else if (this.size === 'large') {
        if (this.query.type) {
          this.$delete(this.query, 'type')
        }
        if (this.query.date) {
          this.$delete(this.query, 'date')
        }
        if (this.query.startTime) {
          this.$delete(this.query, 'startTime')
        }
        if (this.query.endTime) {
          this.$delete(this.query, 'endTime')
        }
        this.changeListCusList()
      }
    },
    resetUse () {
      this.query.page = 1
      this.query.size = 10
      if (this.size === 'small') {
        this.query.type = -1
        this.query.date = null
        this.query.startTime = null
        this.query.endTime = null
        this.getTableList()
      } else if (this.size === 'large') {
        if (this.query.type) {
          this.$delete(this.query, 'type')
        }
        if (this.query.date) {
          this.$delete(this.query, 'date')
        }
        if (this.query.startTime) {
          this.$delete(this.query, 'startTime')
        }
        if (this.query.endTime) {
          this.$delete(this.query, 'endTime')
        }
        this.changeListCusList()
      } else if (this.size === 'apply') {
        this.getApplyInfo()
      }
    },
    changeListCusList () {
      changeListCus(this.query).then(res => {
        this.useData = res.data.content
        this.usePagination.total = res.data.totalElements
      })
    },
    getApplyInfo () {
      applyRecordList().then(res => {
        this.useData = res.data.content
        this.usePagination.total = res.data.totalElements
      })
    },
    getApplyList () {

    },
    getTableList () {
      getList(this.query).then(res => {
        this.useData = res.data.content
        this.usePagination.total = res.data.totalElements
      })
    },
  },
};
</script>
<style lang="less" scoped>
.project {
  min-height: 666px;
}
.search {
  // display: flex;
  position: relative;
  margin-bottom: 30px;
  img {
    z-index: 99;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 39px;
    left: 10px;
  }
  /deep/ .ant-input-affix-wrapper {
    width: 340px;
  }
  /deep/ .ant-input {
    // width: 300px;
    width: 230px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    // margin: 30px 0 20px 0;
    margin: 0 0 20px 0;
    // padding-left: 40px;
  }
  .phase {
    margin: 30px 20px 0;
  }
  span {
    margin-right: 5px;
  }
  /deep/ .ant-select-selection {
    // margin-left: 5px;
    // width: 120px;
    width: 170px;
  }
  button {
    // margin-top: 30px;
    margin-right: 10px;
  }
}
.table {
  /deep/ .ant-list-empty-text {
    min-height: 500px;
    .ant-empty-normal {
      margin-top: 220px;
    }
  }
  /deep/ .ant-list-item {
    border-bottom: none;
    padding: 0 0 20px 0;
  }
  /deep/ .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border: none;
  }
  /deep/ .ant-list-pagination {
    margin: 0 0 8px 0;
  }
  .top {
    width: 920px;
    height: 80px;
    background: #f7faff;
    padding: 21px 41px 17px 32px;
    position: relative;
    border: 1px solid #ebf2f7;
    border-bottom: none;
    font-family: Microsoft YaHei;
    .top1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .top2 {
      font-size: 14px;
      font-weight: 400;
      color: #aaaaaa;
      position: absolute;
      right: 41px;
    }
    .top3 {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-top: 5px;
    }
  }
  .bottom {
    display: flex;
    width: 920px;
    background: #ffffff;
    border: 1px solid #ebf2f7;
    padding: 25px 40px 20px 31px;
    p {
      margin: 0;
    }
    .div1 {
      // width: 100px;
      width: 140px;
    }
    .div2 {
      width: 100px;
    }
    .div3 {
      width: 140px;
    }
    .div4 {
      width: 56px;
    }
    .div5 {
      width: 100px;
    }
    .div6 {
      width: 100px;
    }
    .div7 {
      width: 70px;
    }
    div {
      margin: 0 6px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        word-break: break-word; /* 文本行的任意字内断开 */
        word-wrap: break-word; /* IE */
        &:nth-child(1) {
          color: #aaaaaa;
        }
        &:nth-child(2) {
          color: #333333;
        }
      }
      a {
        margin-right: 10px;
      }
      .applyed {
        width: 88px;
        background: #b2d7f6;
        border: #b2d7f6;
      }
    }
  }
}
.credit-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  .left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 49%;
    height: 100%;
    padding-left: 30px;
    background: #f7faff;
    border-radius: 3px 3px 3px 3px;
    .incressLimit {
      width: 100%;
      padding-left: 80px;
      cursor: pointer;
      // text-align: center;
      color: #007ce2;
    }
    img {
      width: 60px;
      height: 60px;
    }
    .usemoney {
      padding-left: 20px;
      color: #999999;
      .money {
        color: #333333;
        font-size: 30px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
      }
    }
  }
  .ant-btn {
    width: 128px;
    height: 39px;
    font-size: 16px;
    font-weight: bold;
    background: #007ce2;
    border-radius: 5px 5px 5px 5px;
  }
}
.tab {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  .ant-radio-button-wrapper {
    width: 124px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #666666;
    font-size: 16px;
  }
  .ant-radio-button-wrapper-checked {
    color: #007ce2;
    font-weight: bold;
    border: 1px solid #007ce2;
  }
}
.limit {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 20px;
  .limit-block {
    padding-left: 14px;
    font-size: 16px;
    font-weight: bold;
    color: #e72923;
  }
}
.search {
  color: #666666;
  padding-top: 20px;
  ::v-deep .ant-select-selection {
    width: 250px !important;
    height: 32px !important;
  }
  ::v-deep .ant-calendar-picker .ant-calendar-picker-input {
    width: 250px !important;
  }
}
.table {
  ::v-deep .ant-table-column-title {
    font-weight: bold;
    background: #f7faff;
  }
}
.handle-blok {
  margin: 0 auto;
  width: 130px;
  padding-bottom: 30px;
  text-align: center;
  img {
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .info {
    padding-top: 12px;
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .handle-btn {
    width: 88px;
    height: 32px;
    background: #007ce2;
    border-radius: 5px 5px 5px 5px;
  }
}
.prepaymodal {
  ::v-deep .ant-form-item label {
    color: #333;
  }
  ::v-deep .ant-modal-title {
    font-weight: bold;
    color: #1d2129;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  }
  ::v-deep .ant-modal-close .anticon svg {
    margin-top: 20px;
  }
  .upload-btn {
    width: 120px;
    height: 32px;
    margin-top: 3px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #005bac;
  }
  .entBtn {
    padding: 0 40px;
    justify-content: flex-end;
    display: flex;
    .ant-btn-primary {
      margin-right: 20px;
      width: 88px;
      height: 32px;
      background: #007ce2;
      border-radius: 5px 5px 5px 5px;
    }
    .ant-btn {
      width: 88px;
      height: 32px;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #c9d1de;
    }
  }
  .handle-blok {
    margin: 0 auto;
    width: 130px;
    padding-bottom: 30px;
    text-align: center;
    img {
      width: 50px;
      height: 50px;
      text-align: center;
    }
    .info {
      padding-top: 12px;
      padding-bottom: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .handle-btn {
      width: 88px;
      height: 32px;
      background: #007ce2;
      border-radius: 5px 5px 5px 5px;
    }
  }
}
.prepaymodal {
  .label {
    color: #333;
    padding-left: 40px;
    padding-top: 20px;
  }
  .item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .values {
    display: inline-block;
    width: 80%;
    word-break: break-all;
  }
}
.upload-item {
  display: flex;
  align-items: flex-start;
  .label {
    padding-top: 0;
  }
}
.uploads {
  display: inline-block;
  width: 70%;
  /deep/ .ant-upload-list-item-card-actions {
    opacity: 1;
    top: 3px;
  }
  /deep/ .ant-upload-list-item {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
</style>
