import request from '@/utils/request';

export function getList(params) {
  const type = params.type === -1 ? null : params.type
  return request({
    url: '/api/customerCredit/useListCus',
    method: 'get',
    params: {
      ...params,
      date: [],
      type
    },
  });
}

export function changeListCus(params) {
  return request({
    url: '/api/customerCredit/changeListCus',
    method: 'get',
    params: {
      ...params
    },
  });
}

export function getAmountInfo() {
  return request({
    url: '/api/customerCredit/getAmountInfo',
    method: 'get',
  });
}

export function applyRecordList() {
  return request({
    url: '/api/customerCredit/applyRecordList',
    method: 'get',
  });
}

export function applyIncrease(data) {
  return request({
    url: '/api/customerCredit/applyIncrease',
    method: 'post',
    data
  });
}

export default { getList, getAmountInfo, changeListCus, applyRecordList, applyIncrease };
